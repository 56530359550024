export const middleCategorys = [
  {
    name: "Concert",
    path: "/music",
    poster: "image-concert@3x.png",
    imageName: "image-concert-default.png",
    imageHoverName: "image-concert-h.png",
  },
  
  {
    name: "MLB",
    path: "/category/MLB",
    poster: "image-mlb@3x.png",
    imageName: "image-mlb-default.png",
    imageHoverName: "image-mlb-h.png",
  },
  {
    name: "NASCAR",
    path: "/category/NASCAR",
    poster: "image-nascar@3x.png",
    imageName: "image-nascar-n.png",
    imageHoverName: "image-nascar-h.png",
  },
  {
    name: "MLS",
    path: "/category/MLS",
    poster: "image-mls@3x.png",
    imageName: "image-mls-default.png",
    imageHoverName: "image-mls-h.png",
  },
  {
    name: "NFL",
    path: "/category/NFL",
    poster: "image-nfl@3x.png",
    imageName: "image-nfl-default.png",
    imageHoverName: "image-nfl-h.png",
  },
  // {
  //   name: "INDY500",
  //   path: "/category/INDY500",
  //   poster: "image-indy@3x.png",
  //   imageName: "image-indy-n.png",
  //   imageHoverName: "image-indy-h.png",
  // },
 
  // {
  //   name: "PGA",
  //   path: "/category/PGA",
  //   poster: "image-pga@3x.png",
  //   imageName: "image-pga-n.png",
  //   imageHoverName: "image-pga-h.png",
  // },

  // {
  //   name: "KentuckyDerby",
  //   path: "/category/KentuckyDerby",
  //   poster: "image-kd@3x.png",
  //   imageName: "image-KD-default.png",
  //   imageHoverName: "image-KD-h.png",

  // },

  {
    name: "NHL",
    path: "/category/NHL",
    poster: "image-nhl@3x.png",
    imageName: "image-nhl-default.png",
    imageHoverName: "image-nhl-h.png",
  },
  {
    name: "Tennis",
    path: "/category/Tennis",
    poster: "image-tennis@3x.png",
    imageName: "image-tennis-default.png",
    imageHoverName: "image-tennis-h.png",
  },
  {
    name: "Golf",
    path: "/category/Golf",
    poster: "image-golf@3x.png",
    imageName: "image-golf-default.png",
    imageHoverName: "image-golf-h.png",
  },
  {
    name: "NBA",
    path: "/category/NBA",
    poster: "image-nba@3x.png",
    imageName: "image-nba-default.png",
    imageHoverName: "image-nba-h.png",
  },
  {
    name: "Shows",
    path: "/shows",
    poster: "image-shows@3x.png",
    imageName: "image-shows-default.png",
    imageHoverName: "image-shows-h.png",
  },
];

export const categorysInfo = {
  Concert: {
    headerImage: "",
    desktopHeader: "",
    title: "",
    keywords:"",
    category: "Concert",
    subcategory: "",
  },
  // NCAA: {
  //   headerImage: "image-header-nba@3x.jpg",
  //   desktopHeader: "header-nba.jpg",
  //   title: "NCAA Games",
  //   keywords:"",
  //   category: "",
  //   subcategory: "(Div I-A and Div I-AA)",
  // },

  //   INDY500: {
  //   headerImage: "image-header-indy@3x.jpg",
  //   desktopHeader: "header-indy.jpg",
  //   title: "Indy 500",
  //   keywords:"Indianapolis 500",
  //   category: "",
  //   subcategory: "",
  // },

  NFL: {
    headerImage: "image-header-nfl@3x.jpg",
    desktopHeader: "header-nfl.jpg",
    title: "NFL Games",
    keywords:"",
    category: "",
    subcategory: "NFL",
  },

  NASCAR: {
    headerImage: "image-header-indy@3x.jpg",
    desktopHeader: "header-indy.jpg",
    title: "NASCAR",
    keywords:"NASCAR",
    category: "",
    subcategory: "",
  },

  // Masters: {
  //   headerImage: "image-header-golf@3x.jpg",
  //   desktopHeader: "header-golf.jpg",
  //   title: "Masters Golf Tournament",
  //   keywords:"Masters Golf Tournament",
  //   category: "",
  //   subcategory: "Golf",
  // },

  // PGA: {
  //   headerImage: "image-header-golf@3x.jpg",
  //   desktopHeader: "header-golf.jpg",
  //   title: "PGA Professional Championship",
  //   keywords:"PGA Championship",
  //   category: "",
  //   subcategory: "Professional (PGA)",
  // },

  
  // KentuckyDerby: {
  //   headerImage: "image-header-KD@3x.jpg",
  //   desktopHeader: "header-KD.jpg",
  //   title: "Kentucky Derby",
  //   keywords:"Kentucky Derby",
  //   category: "",
  //   subcategory: "",
  // },




  MLB: {
    headerImage: "image-header-mlb@3x.jpg",
    desktopHeader: "header-mlb.jpg",
    title: "MLB Games",
    keywords:"",
    category: "",
    subcategory: "MLB",
  },



  MLS: {
    headerImage: "image-header-mls@3x.jpg",
    desktopHeader: "header-mls.jpg",
    title: "MLS Games",
    keywords:"",
    category: "",
    subcategory: "MLS",
  },
  NHL: {
    headerImage: "image-header-nhl@3x.jpg",
    desktopHeader: "header-nhl.jpg",
    title: "NHL Games",
    keywords:"",
    category: "",
    subcategory: "NHL",
  },
  Tennis: {
    headerImage: "image-header-tennis@3x.jpg",
    desktopHeader: "header-tennis.jpg",
    title: "Tennis Games",
    keywords:"",
    category: "",
    subcategory: "Tennis",
  },
  Golf: {
    headerImage: "image-header-golf@3x.jpg",
    desktopHeader: "header-golf.jpg",
    title: "Golf Games",
    keywords:"",
    category: "",
    subcategory: "Golf",
  },
  NBA: {
    headerImage: "image-header-nba@3x.jpg",
    desktopHeader: "header-nba.jpg",
    title: "NBA Games",
    keywords:"",
    category: "",
    subcategory: "NBA",
  },
  Shows: {
    headerImage: "",
    desktopHeader: "header-golf.jpg",
    title: "",
    keywords:"",
    category: "Theatre",
    subcategory: "",
  },
};

export const mainCategory = [
  { title: "Music", key: "MUSICS" },
  { title: "Sports", key: "SPORTS" },
  { title: "Shows", key: "SHOWS" },
];

export const subcategoryMap = {
  Music: [
    {
      title: "All",
      emoji: "🤩",
      key: "",
    },
    {
      title: "Concerts",
      emoji: "🧑‍🎤",
      key: "Concerts",
    },
    {
      title: "Festivals",
      emoji: "🤘",
      key: "Festival",
    },
  ],
  Sports: [
    {
      title: "All",
      emoji: "🤩",
      key: "",
    },
    {
      title: "NFL",
      emoji: "🏈",
      key: "NFL",
    },
    {
      title: "MLB",
      emoji: "⚾",
      key: "Professional (MLB)",
    },
    {
      title: "NBA",
      emoji: "🏀",
      key: "Professional (NBA)",
    },
    {
      title: "NHL",
      emoji: "🏒",
      key: "Professional (NHL)",
    },
    {
      title: "MLS",
      emoji: "⚽",
      key: "Professional (MLS)",
    },
    {
      title: "NCAA BB",
      emoji: "🏀",
      key: "College (Div I-A and Div I-AA)",
    },
    {
      title: "NCAA FB",
      emoji: "🏈",
      key: "College (Div I-A and Div I-AA)",
    },
    {
      title: "NASCAR",
      emoji: "🏁",
      key: "NASCAR",
    },
    {
      title: "Tennis",
      emoji: "🎾",
      key: "Tennis",
    },
    {
      title: "Golf",
      emoji: "⛳",
      key: "Golf",
    },
    {
      title: "Boxing",
      emoji: "🥊",
      key: "Boxing",
    },
    {
      title: "Wrestling",
      emoji: "🤼‍",
      key: "Wrestling",
    },
    {
      title: "MMA",
      emoji: "🥋",
      key: "MMA",
    },
    {
      title: "WWE",
      emoji: "🤼‍♂️",
      key: "WWE",
    },
    {
      title: "Horse Racing",
      emoji: "🏇",
      key: "Horse",
    },
  ],
  Shows: [
    {
      title: "All",
      key: "",
    },
    {
      title: "Broadway",
      key: "Broadway",
    },
    {
      title: "Off-Broadway",
      key: "Off-Broadway",
    },
    {
      title: "Cirque du soleil",
      key: "Cirque du soleil",
    },
    {
      title: "Musical/Play",
      key: "Musical/Play",
    },
    {
      title: "Las Vegas Shows",
      key: "Las Vegas Shows",
    },
    {
      title: "Ballet",
      key: "Ballet",
    },
    {
      title: "Opera",
      key: "Opera",
    },
    {
      title: "Dance",
      key: "Dance",
    },
    {
      title: "Children/Family",
      key: "Children/Family",
    },
    {
      title: "West End",
      key: "West End",
    },
    {
      title: "Circus",
      key: "Circus",
    },
    {
      title: "Magic Shows",
      key: "Magic Shows",
    },
    {
      title: "Religious",
      key: "Religious",
    },
    {
      title: "Lecture",
      key: "Lecture",
    },
    {
      title: "Film",
      key: "Film",
    },
    {
      title: "Museum/Exhibit",
      key: "Museum/Exhibit",
    },
  ],
};

export const allEventsFilterCategorys = [
  {
    title: "All",
    normalImageName: "categorys/icons/icon-all-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-all-selected@3x.png",
    keyWord: "",
  },
  {
    title: "Concert",
    normalImageName: "categorys/icons/icon-concerts-unselect@3x.png",
    selectedImageName: "categorys/icons/icon-concerts-unselect@3x-1.png",
    keyWord: "Concert",
  },
  {
    title: "Festival",
    normalImageName: "categorys/icons/icon-festivals-unselect@3x.png",
    selectedImageName: "categorys/icons/icon-festivals-unselect@3x-1.png",
    keyWord: "Festival",
  },
  {
    title: "Musical\n/Play",
    normalImageName: "categorys/icons/icon-play-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-play-selected@3x.png",
    keyWord: "Musical",
  },
  {
    title: "Soccer",
    normalImageName: "categorys/icons/icon-soccer-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-soccer-selected@3x.png",
    keyWord: "Soccer",
  },
  {
    title: "Basketball",
    normalImageName: "categorys/icons/icon-basketball-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-basketball-selected@3x.png",
    keyWord: "Basketball",
  },
  {
    title: "Golf",
    normalImageName: "categorys/icons/icon-golf-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-golf-selected@3x.png",
    keyWord: "Golf",
  },
  {
    title: "Hockey",
    normalImageName: "categorys/icons/icon-hockey-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-hockey-selected@3x.png",
    keyWord: "Hockey",
  },
  {
    title: "Baseball",
    normalImageName: "categorys/icons/icon-baseball-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-baseball-selected@3x.png",
    keyWord: "Baseball",
  },
  {
    title: "Tennis",
    normalImageName: "categorys/icons/icon-tennis-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-tennis-selected@3x.png",
    keyWord: "Tennis",
  },
  {
    title: "Football",
    normalImageName: "categorys/icons/icon-football-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-football-selected@3x.png",
    keyWord: "Football",
  },
  {
    title: "Fairs\n/Festivals",
    normalImageName: "categorys/icons/icon-fairs-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-fairs-selected@3x.png",
    keyWord: "Fairs,Festivals",
  },
  {
    title: "Las Vegas Shows",
    normalImageName: "categorys/icons/icon-lasvegas-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-lasvegas-selected@3x.png",
    keyWord: "Las Vegas Shows",
  },
  {
    title: "Ballet",
    normalImageName: "categorys/icons/icon-ballet-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-ballet-selected@3x.png",
    keyWord: "Ballet",
  },
  {
    title: "Dance",
    normalImageName: "categorys/icons/icon-dance-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-dance-selected@3x.png",
    keyWord: "Dance",
  },
  {
    title: "Children\n/Family",
    normalImageName: "categorys/icons/icon-children-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-children-selected@3x.png",
    keyWord: "Children,Family",
  },
  {
    title: "Cirque du soleil",
    normalImageName: "categorys/icons/icon-cds-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-cds-selected@3x.png",
    keyWord: "Cirque du soleil",
  },
  {
    title: "Comedy",
    normalImageName: "categorys/icons/icon-comedy-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-comedy-selected@3x.png",
    keyWord: "Comedy",
  },
  {
    title: "Opera",
    normalImageName: "categorys/icons/icon-opera-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-opera-selected@3x.png",
    keyWord: "Opera",
  },
  {
    title: "Lecture",
    normalImageName: "categorys/icons/icon-lecture-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-lecture-selected@3x.png",
    keyWord: "Lecture",
  },
  {
    title: "Film",
    normalImageName: "categorys/icons/icon-film-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-film-selected@3x.png",
    keyWord: "Film",
  },
  {
    title: "Museum\n/Exhibit",
    normalImageName: "categorys/icons/icon-museum-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-museum-selected@3x.png",
    keyWord: "Museum,Exhibit",
  },
  {
    title: "Taped Programs",
    normalImageName: "categorys/icons/icon-tp-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-tp-selected@3x.png",
    keyWord: "Taped Programs",
  },
  {
    title: "Adult",
    normalImageName: "categorys/icons/icon-adult-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-adult-selected@3x.png",
    keyWord: "Adult",
  },
  {
    title: "Broadway",
    normalImageName: "categorys/icons/icon-broadway-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-broadway-selected@3x.png",
    keyWord: "Broadway",
  },
  {
    title: "Off-Broadway",
    normalImageName: "categorys/icons/icon-offbroadway-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-offbroadway-selected@3x.png",
    keyWord: "Off-Broadway",
  },
  {
    title: "Wrestling",
    normalImageName: "categorys/icons/icon-wrestling-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-wrestling-selected@3x.png",
    keyWord: "Wrestling",
  },
  {
    title: "Volleyball",
    normalImageName: "categorys/icons/icon-volleyball-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-volleyball-selected@3x.png",
    keyWord: "Volleyball",
  },
  {
    title: "Boxing",
    normalImageName: "categorys/icons/icon-boxing-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-boxing-selected@3x.png",
    keyWord: "Boxing",
  },
  {
    title: "Skating",
    normalImageName: "categorys/icons/icon-skating-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-skating-selected@3x.png",
    keyWord: "Skating",
  },
  {
    title: "Rodeo",
    normalImageName: "categorys/icons/icon-rodeo-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-rodeo-selected@3x.png",
    keyWord: "Rodeo",
  },
  {
    title: "Lacrosse",
    normalImageName: "categorys/icons/icon-lacrosse-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-lacrosse-selected@3x.png",
    keyWord: "Lacrosse",
  },
  {
    title: "Rugby",
    normalImageName: "categorys/icons/icon-rugby-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-rugby-selected@3x.png",
    keyWord: "Rugby",
  },
  {
    title: "Gymnastics",
    normalImageName: "categorys/icons/icon-gymnastics-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-gymnastics-selected@3x.png",
    keyWord: "Gymnastics",
  },
  {
    title: "Softball",
    normalImageName: "categorys/icons/icon-softball-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-softball-selected@3x.png",
    keyWord: "Softball",
  },
  {
    title: "Martial Arts",
    normalImageName: "categorys/icons/icon-mma-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-mma-selected@3x.png",
    keyWord: "Martial Arts",
  },
  {
    title: "Auto Racing",
    normalImageName: "categorys/icons/icon-auto-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-auto-selected@3x.png",
    keyWord: "Auto Racing",
  },
  {
    title: "Motorcycle Racing",
    normalImageName: "categorys/icons/icon-motorcycle-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-motorcycle-selected@3x.png",
    keyWord: "Motorcycle Racing",
  },
  {
    title: "Horse Racing",
    normalImageName: "categorys/icons/icon-horse-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-horse-selected@3x.png",
    keyWord: "Horse",
  },
  {
    title: "Other Racing",
    normalImageName: "categorys/icons/icon-racing-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-racing-selected@3x.png",
    keyWord: "Other Racing",
  },
  {
    title: "Circus",
    normalImageName: "categorys/icons/icon-circus-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-circus-selected@3x.png",
    keyWord: "Circus",
  },
  {
    title: "Magic Shows",
    normalImageName: "categorys/icons/icon-magic-unselected@3x.png",
    selectedImageName: "categorys/icons/icon-magic-selected@3x.png",
    keyWord: "Magic Shows",
  },
];
